import { Box, styled, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Grid2 from "@mui/material/Unstable_Grid2";
import React, { Fragment, useEffect } from "react";
import { colors } from "../styles/colors";
import { transientOptions } from "../utils/transitient-utils";
import { isSmallScreen } from "../utils/style-utils";
import { runtimeEnvIsNode } from "../utils/runtime";
import { useMenuItems } from "../hooks/useMenuItems";
import { dimensions } from "../styles/dimensions";
import { useNavigation } from "../hooks/useNavigation";
import { useAppContext } from "../context/AppContext";

const NavItem = styled(
  Box,
  transientOptions
)(({ theme }) => ({
  cursor: "pointer",
  userSelect: "none",
  textAlign: "center",
  position: "relative",
  textTransform: "uppercase",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: 0,
  paddingRight: 0,
  height: "none",
  display: "unset",
  alignItems: "none",
  justifyContent: "none",
  border: "none",
  "&:hover": {
    color: "none",
    backgroundColor: "none",
  },
}));

const BurgerMenuIcon = styled(Box)(({ theme }) => ({
  position: "absolute",
  display: "flex",
  top: dimensions.navHeightMobileRaw / 2 - 17.5,
  right: theme.spacing(2),
  zIndex: 1002,
  color: colors.black,
}));

type MobileNavWrapperProps = {
  $isMenuOpen: boolean;
};

const MobileNavWrapper = styled(
  Box,
  transientOptions
)<MobileNavWrapperProps>(({ $isMenuOpen, theme }) => ({
  backgroundColor: colors.isabelline,
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  width: "100%",
  height: "100vh",
  left: $isMenuOpen ? 0 : "100%",
  top: dimensions.navHeightMobile,
  zIndex: 101,
  overflowY: "auto",
  paddingLeft: theme.spacing(4),
  paddingTop: theme.spacing(6),
  transition: "left 0.25s ease-in-out",
}));

const MobileNavItemWrapper = styled(
  Box,
  transientOptions
)(({ theme }) => ({
  paddingLeft: 0,
  paddingBottom: theme.spacing(2),
  userSelect: "none",
  display: "flex",
}));

const StyledTypography = styled(Typography)(({}) => ({
  color: colors.raisinblack,
  textTransform: "uppercase",
  "&:hover": {
    color: colors.lightGrey,
  },
}));

type MobileNavItemProps = {
  onClick?: () => void;
  text: string | null | undefined;
};

const MobileNavItem = ({ onClick, text }: MobileNavItemProps) => (
  <MobileNavItemWrapper>
    <Box sx={{ display: "flex", position: "relative" }} onClick={onClick}>
      <StyledTypography variant={"h2"}>{text}</StyledTypography>
    </Box>
  </MobileNavItemWrapper>
);

const Navigation: React.FC = () => {
  const navItems = useMenuItems();
  const { isMenuOpen, setIsMenuOpen } = useAppContext();
  const isMobile = isSmallScreen();
  const { scrollToSection } = useNavigation();

  const handleMenuItemClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (!runtimeEnvIsNode) {
      if (isMobile && isMenuOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
    }
  }, [isMobile, isMenuOpen]);

  return (
    <Fragment>
      {/* Mobile navigation BEGIN */}
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <BurgerMenuIcon onClick={handleMenuItemClick}>
          {isMenuOpen ? (
            <CloseIcon fontSize="large" />
          ) : (
            <MenuIcon fontSize="large" />
          )}
        </BurgerMenuIcon>
        <MobileNavWrapper $isMenuOpen={isMenuOpen}>
          <Box>
            {navItems.map((navItem, index) => (
              <MobileNavItem
                key={index}
                onClick={() => {
                  scrollToSection(index + 1);
                  handleMenuItemClick();
                }}
                text={navItem.title}
              />
            ))}
          </Box>
        </MobileNavWrapper>
      </Box>
      {/* Mobile navigation END */}
      {/* Desktop navigation BEGIN */}
      <Box sx={{ display: { xs: "none", md: "block" }, pt: 1.5, pb: 1.5 }}>
        <Grid2 container columnSpacing={4}>
          {navItems.map((navItem, index) => (
            <Grid2 key={index}>
              <NavItem onClick={() => scrollToSection(index + 1)}>
                <StyledTypography>{navItem.title}</StyledTypography>
              </NavItem>
            </Grid2>
          ))}
        </Grid2>
      </Box>
      {/* Desktop navigation END */}
    </Fragment>
  );
};

export default Navigation;
