import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react";
import Header from "../components/Header";
import "../../styles.css";
import AppProvider from "../context/AppContext";
import ImagesProvider from "../context/ImagesContext";

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box sx={{ overflowX: "hidden", position: "relative" }}>
      <AppProvider>
        <ImagesProvider>
          <Header />
          {children}
        </ImagesProvider>
      </AppProvider>
    </Box>
  );
};

export default Layout;
