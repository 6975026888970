import { graphql, useStaticQuery } from 'gatsby';

type MenuItem = {
  id: string;
  title: string;
};

export const useMenuItems = (): MenuItem[] => {
  const data = useStaticQuery(graphql`
    query {
      productsJson {
        id
        title
      }
      storyJson {
        id
        title
      }
      contactJson {
        id
        title
      }
    }
  `);

  const menuItems = [data.productsJson, data.storyJson, data.contactJson];

  return menuItems;
};

