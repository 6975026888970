export const colors = {
  primaryColor: '#E30613',
  secondaryColor: '#1B5A77',
  lightGrey: '#bdbdbd',
  primaryGreen: '#379237',
  secondaryGreen: '#54B435',
  // NEW COLORS
  pepperoniBlue: '#00AEEF',
  margheritaGreen: '#00A650',
  basilicaGreen: '#93B43C',
  isabelline: '#F2EFEA',
  raisinblack: '#272727',
  // slategray: '#747C92',
  // wenge: '#564E58',
  // gunmetal: '#333745',
  // timberwolf: '#D7D0C8',
  green: '#009640',
  red: '#E30613',
  white: 'white',
  black: 'black',
};

